<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16667 5.66667V3L2.5 7.66667L7.16667 12.3333V9.6C10.5 9.6 12.8333 10.6667 14.5 13C13.8333 9.66667 11.8333 6.33333 7.16667 5.66667Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcCurvedArrow',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
