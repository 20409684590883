import { render, staticRenderFns } from "./ic-curved-arrow.vue?vue&type=template&id=5e625399&scoped=true"
import script from "./ic-curved-arrow.vue?vue&type=script&lang=js"
export * from "./ic-curved-arrow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e625399",
  null
  
)

export default component.exports